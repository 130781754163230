@font-face {
  font-family: Tajawal;
  src:
    url("./fonts/general/Tajawal-Regular.woff2") format("woff2"),
    url("./fonts/general/Tajawal-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Tajawalbold;
  src:
    url("./fonts/general/Tajawal-Bold.woff2") format("woff2"),
    url("./fonts/general/Tajawal-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sectionTitle {
    @apply text-center text-4xl text-black-800
     md:text-5xl md:leading-[1];
  }
  .sectionSubTitle {
    @apply text-center text-xl
     text-gray-600 md:leading-[1];
  }
  .paddingXContainer {
    @apply px-4 lg:px-6;
  }
  .paddingYContainer {
    @apply py-6 lg:py-8;
  }
  .allFormConatiner {
    @apply paddingXContainer paddingYContainer m-auto
     flex min-h-screen max-w-screen-xl flex-col
      items-center justify-center;
  }
  .landingContainer {
    @apply paddingYContainer paddingXContainer
     m-auto h-full max-w-screen-xl;
  }
  .landingSubContainer {
    @apply mt-[60px] flex h-full w-full flex-col items-center justify-center
     md:mt-[80px] lg:mt-[140px];
  }
  .marignSubContainerContent {
    @apply mt-[60px];
  }
  .transitionProp {
    @apply transition-all 
                   duration-100 ease-linear;
  }
  .transitionNav {
    @apply transition-all duration-[200ms] ease-in-out;
  }
  .formRoot {
    @apply transitionProp relative m-auto mt-10 w-full
       max-w-lg rounded-sm border-[1px] border-gray-200 bg-gray-50/75 p-5 text-black-800;
  }
  .formField {
    @apply mb-[5px] grid;
  }
  .formFieldContainer {
    @apply flex items-baseline justify-between;
  }
  .formFieldLabel {
    @apply text-[15px] leading-[35px];
  }
  .formFieldMessage {
    @apply text-[13px] text-red-primary;
  }
  .formFieldInput {
    @apply transitionProp box-border inline-flex h-[40px] w-full appearance-none items-center
               justify-center rounded-sm border-[1px]
                border-gray-200 bg-white px-[10px] 
                text-[18px] leading-none 
                 text-black-800 outline-none
               selection:bg-[#5EEBFD]
                   selection:text-black-800 focus:border-black-800 lg:hover:border-gray-400;
  }
  .textAreaInput {
    @apply transitionProp box-border block w-full rounded-sm border-[1px]
          border-gray-300 bg-gray-50 p-2.5
            text-sm text-blackA12
          outline-none placeholder:text-blackA11
           focus:border-blackA12 lg:hover:border-gray-400;
  }
  .submitButton {
    @apply transitionProp mt-5 box-border inline-flex 
          h-[40px] w-full
          appearance-none items-center justify-center
           rounded-sm bg-black-600
              text-white
               focus:shadow-[0_0_0_2px]
                focus:shadow-black-600/60 focus:outline-none disabled:opacity-[0.5]
                 disabled:hover:bg-black-600
               lg:hover:bg-black-800;
  }
  .normalButton {
    @apply transitionProp w-full
       cursor-pointer rounded-sm
    border-[2px] border-gray-300 p-2 text-lg text-white
     opacity-[1] md:text-xl lg:group-hover:opacity-[0.9];
  }

  .dropzoneContainer {
    @apply transitionProp relative mb-[10px] flex
     h-[100px] w-full cursor-pointer items-center
      justify-center rounded-sm
       border-[2px] border-dashed
        border-gray-200 bg-white p-2
         text-center text-[18px]
         text-gray-500
          focus:border-black-800 lg:hover:border-gray-400;
  }
  .spinerContainerFixed {
    @apply makeItBlur fixed top-0 z-[200] flex h-full
         w-full items-center justify-center;
  }
  .spinerContainerAbsolute {
    @apply makeItBlur absolute
         left-0 right-0 top-0 flex
          h-full w-full flex-col items-center
           justify-center gap-2 rounded-sm;
  }
  .countDown {
    @apply mt-[40px] flex h-full w-full animate-counterDown 
  flex-col items-center justify-center pb-1 lg:w-[50%]
  lg:items-start lg:justify-start xl:mt-0;
  }
  .alertButton {
    @apply inline-flex h-[35px]
                   items-center justify-center
                    rounded-[4px] bg-red4 px-[15px]
                     font-medium leading-none
                      text-red11 hover:bg-red5
                       focus:shadow-[0_0_0_2px]
                        focus:shadow-red7 focus:outline-none;
  }

  .magnifierLens {
    @apply absolute
                 z-[12] h-[150px] 
            w-[175px]
                  border-[1px] border-yellow-300 bg-yellow-300/25;
  }
  .magnifierImage {
    @apply absolute right-0 top-0
                 z-[11] h-full
                  w-full rounded-[5px] border-[1px] border-yellow-300;
  }
  .votingImage {
    @apply z-[10] m-auto h-full max-h-screen w-auto max-w-full border-[1px] border-gray-600 object-cover 
                      transition-all duration-[0.4s] ease-in-out;
  }
}

body {
  margin: 0;
  font-family: Tajawal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
h1 {
  font-family: Tajawalbold;
}
.makeItBlur {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.Toastify__toast-body {
  padding: 5px !important;
}
.Toastify__progress-bar--error {
  background-color: var(--red-primary) !important;
}
#priceChildCard {
  transition: all 0.4s ease-in-out;
  transform: translate3d(0px, 0px, 0px);
  box-shadow: 0 0 0 rgb(0 0 0 / 0.2);
  will-change: transform, box-shadow;
}
#priceParentCard:hover #priceChildCard {
  transform: translate3d(0px, -10px, 0);
  transform-style: preserve-3d;
  box-shadow: 0 0 5px rgb(0 0 0 / 0.2);
}
#menuNavbarText {
  transition: all 0.2s ease-in-out;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform;
}
#menuNavbar:hover #menuNavbarText {
  transform: translate3d(0px, -4px, 0);
  transform-style: preserve-3d;
}

.countDown-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
